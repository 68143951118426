<template>
  <div>
    <i2-form-vue-select
      v-model="selectedValue"
      v-bind="$attrs"
      :options="estados"
      :name="name"
      :label="label"
      :rules="rules"
    />
  </div>
</template>

<script>

export default {
  name: 'StatesBr',
  props: {
    name: {
      default: '',
      type: String,
      required: true,
    },
    label: {
      default: '',
      type: String,
      required: true,
    },
    rules: {
      default: '',
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
  },
  data() {
    return {
      estados: [
        { sigla: 'AC', text: 'AC' },
        { sigla: 'AL', text: 'AL' },
        { sigla: 'AP', text: 'AP' },
        { sigla: 'AM', text: 'AM' },
        { sigla: 'BA', text: 'BA' },
        { sigla: 'CE', text: 'CE' },
        { sigla: 'DF', text: 'DF' },
        { sigla: 'ES', text: 'ES' },
        { sigla: 'GO', text: 'GO' },
        { sigla: 'MA', text: 'MA' },
        { sigla: 'MT', text: 'MT' },
        { sigla: 'MS', text: 'MS' },
        { sigla: 'MG', text: 'MG' },
        { sigla: 'PA', text: 'PA' },
        { sigla: 'PB', text: 'PB' },
        { sigla: 'PR', text: 'PR' },
        { sigla: 'PE', text: 'PE' },
        { sigla: 'PI', text: 'PI' },
        { sigla: 'RJ', text: 'RJ' },
        { sigla: 'RN', text: 'RN' },
        { sigla: 'RS', text: 'RS' },
        { sigla: 'RO', text: 'RO' },
        { sigla: 'RR', text: 'RR' },
        { sigla: 'SC', text: 'SC' },
        { sigla: 'SP', text: 'SP' },
        { sigla: 'SE', text: 'SE' },
        { sigla: 'TO', text: 'TO' },
        // { sigla: 'AC', text: 'Acre' },
        // { sigla: 'AL', text: 'Alagoas' },
        // { sigla: 'AP', text: 'Amapá' },
        // { sigla: 'AM', text: 'Amazonas' },
        // { sigla: 'BA', text: 'Bahia' },
        // { sigla: 'CE', text: 'Ceará' },
        // { sigla: 'DF', text: 'Distrito Federal' },
        // { sigla: 'ES', text: 'Espírito Santo' },
        // { sigla: 'GO', text: 'Goiás' },
        // { sigla: 'MA', text: 'Maranhão' },
        // { sigla: 'MT', text: 'Mato Grosso' },
        // { sigla: 'MS', text: 'Mato Grosso do Sul' },
        // { sigla: 'MG', text: 'Minas Gerais' },
        // { sigla: 'PA', text: 'Pará' },
        // { sigla: 'PB', text: 'Paraíba' },
        // { sigla: 'PR', text: 'Paraná' },
        // { sigla: 'PE', text: 'Pernambuco' },
        // { sigla: 'PI', text: 'Piauí' },
        // { sigla: 'RJ', text: 'Rio de Janeiro' },
        // { sigla: 'RN', text: 'Rio Grande do Norte' },
        // { sigla: 'RS', text: 'Rio Grande do Sul' },
        // { sigla: 'RO', text: 'Rondônia' },
        // { sigla: 'RR', text: 'Roraima' },
        // { sigla: 'SC', text: 'Santa Catarina' },
        // { sigla: 'SP', text: 'São Paulo' },
        // { sigla: 'SE', text: 'Sergipe' },
        // { sigla: 'TO', text: 'Tocantins' },
      ],
      selectedValue: '',
    }
  },
  watch: {
    selectedValue(val) {
      this.$emit('onSelect', JSON.parse(JSON.stringify(val.sigla)))
      this.$emit('input', JSON.parse(JSON.stringify(val.sigla)))
    },
    value(val) {
      if (val) {
        this.selectedValue = val
      }
    },
  },
}
</script>

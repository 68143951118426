const hiringType = [
  {
    path: '/hiring-types',
    name: 'hiring-types.list',
    component: () => import('@/views/hiringtype/HiringTypeList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default hiringType

const person = [
  {
    path: '/persons',
    name: 'persons.list',
    component: () => import('@/views/person/PersonList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },

  },
]

export default person

const users = [
  {
    path: '/clients',
    name: 'clients.list',
    component: () => import('@/views/clients/ClientList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },

  },
]

export default users

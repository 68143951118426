const map = [
  {
    path: '/maps',
    name: 'maps.list',
    component: () => import('@/views/map/MapList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/create',
    name: 'maps.create',
    component: () => import('@/views/map/MapForm.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/show/:uuid',
    name: 'maps.edit',
    component: () => import('@/views/map/MapForm.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/:uuid/period',
    name: 'maps-period.edit',
    component: () => import('@/views/map/TimeMapPeriod'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
  {
    path: '/maps/:uuid/resources',
    name: 'maps-resources.edit',
    component: () => import('@/views/map/TimeMapPeriodResource'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default map

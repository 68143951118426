const specialty = [
  {
    path: '/specialties',
    name: 'specialties.list',
    component: () => import('@/views/specialty/SpecialtyList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },

  },
]

export default specialty

const timeMap = [
  {
    path: '/time-maps',
    name: 'time-maps.list',
    component: () => import('@/views/timemap/TimeMapList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default timeMap

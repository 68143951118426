const team = [
  {
    path: '/teams',
    name: 'teams.list',
    component: () => import('@/views/team/TeamList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default team

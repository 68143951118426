const scales = [
  {
    path: '/scales',
    name: 'scales.list',
    component: () => import('@/views/duty/DutyCalendarView.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default scales

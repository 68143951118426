const professionalsspecialty = [
  {
    path: '/professionals-specialties',
    name: 'professionals-specialties.list',
    component: () => import('@/views/professionalsspecialty/ProfessionalsSpecialtyList.vue'),
    meta: {
      roles: ['super_admin', 'doctor', 'admin', 'analyst'],
    },

  },
]

export default professionalsspecialty

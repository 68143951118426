import { isUserLoggedIn, userRole } from '@/auth/utils'
import map from '@/router/map'
import password from '@/router/password'
import professional from '@/router/professional'
import team from '@/router/team'
import timemap from '@/router/timemap'
import unity from '@/router/unity'
import users from '@/router/users'
import clients from '@/router/clients'
import person from '@/router/person'
import professionalunities from '@/router/professionalunities'
import specialty from '@/router/specialty'
import professionalsspecialty from '@/router/professionalsspecialty'
import scales from '@/router/scales'
import shift from '@/router/shift'
import paymentmethod from '@/router/paymentmethod'
import hiringtype from '@/router/hiringtype'
import operator from '@/router/operator'
import patient from '@/router/patient'
import status from '@/router/status'
import shiftsstatusapprovals from '@/router/shiftsstatusapprovals'
import checkin from '@/router/checkin'
import reason from '@/router/reason'
import checkinapproval from '@/router/checkinapproval'
import shiftlog from '@/router/shiftlog'
import tag from '@/router/tag'
import invite from '@/router/invite'
        // :routes-imports://

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
  {
    path: '/professional/register/:email',
    name: 'register.professional',
    component: () => import('@/views/professional/Register.vue'),
    props: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...unity,
    ...team,
    ...professional,
    ...map,
    ...timemap,
    ...person,
    ...professionalunities,
    ...specialty,
    ...professionalsspecialty,
    ...scales,
    ...shift,
    ...paymentmethod,
    ...hiringtype,
    ...operator,
    ...patient,
    ...status,
    ...shiftsstatusapprovals,
    ...checkin,
    ...reason,
    ...checkinapproval,
    ...shiftlog,
    ...tag,
    ...invite,
// :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404'
        || to.name === 'login'
        || to.name === 'perdi-minha-senha'
        || to.name === 'recuperar-senha'
        || to.name === 'trocar-senha'
        || to.name === 'register.professional') {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('/')
      return false
    }
  }

  const roles = to.meta.roles || []

  if (roles.length > 0 && !roles.find(item => item === userRole())) {
    router.push({ name: 'error-404' })
    return false
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
